import { useEffect } from 'react';

import { EventName, EventOptionPaymentStepName } from 'enums';
import { gaUtils } from 'utils';

export const useTrackPaymentStepDuration = (
  stepName: EventOptionPaymentStepName,
) => {
  useEffect(() => {
    const startDate = new Date();
    gaUtils.trackEvent(EventName.PaymentStepStart, {
      step_name: stepName,
    });

    return () => {
      const endDate = new Date();
      const timing_sec = (endDate.getTime() - startDate.getTime()) / 1000;

      gaUtils.trackEvent(EventName.PaymentStepEnd, {
        step_name: stepName,
        timing_sec,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
