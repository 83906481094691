import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from 'components';
import { LOADING_ICON_PATH } from 'constants/images.constants';
import { TranslationNamespace } from 'i18n';

export const HelpPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common);

  return (
    <InfoCard
      description={t('pages.help.title')}
      icon={{
        src: LOADING_ICON_PATH,
        alt: 'loading',
      }}
    />
  );
};
