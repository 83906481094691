import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/sci-domains`;

const getOne = async () => (await axios.get(`${url}`)).data;

export const sciDomainsApi = {
  getOne,
};
