import cx from 'classnames';
import { find, first, toLower } from 'lodash';
import React, { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import {
  Button,
  CountdownTimer,
  PaymentLayout,
  PaymentLayoutAction,
} from 'components';
import { PaymentDetailsRules } from 'components/PaymentDetailsRules';
import {
  COPY_ICON_PATH,
  EXCLAMATION_CLOUD_ICON_PATH,
  IMAGES_PATH,
} from 'constants/images.constants';
import { PaymentType, EventOptionPaymentStepName, Bank } from 'enums';
import { useOrderContext, useTrackPaymentStepDuration } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  Currency,
  Order,
  Requisites,
  RequisitesCard,
  RequisitesSBP,
  TradeMethod,
} from 'types';

import './PaymentDetails.style.scss';

type Props = {
  order: Order;
  currencies: Array<Currency>;
  refetchOrderStatus: () => void;
  actions: PaymentLayoutAction[];
  tradeMethod: TradeMethod;
  banks: Bank[];
};

type Field = {
  name: string;
  type: string;
  value: string;
};

export const PaymentDetails: React.FC<Props> = ({
  order,
  currencies,
  refetchOrderStatus,
  actions,
  tradeMethod,
  banks,
}) => {
  useTrackPaymentStepDuration(
    EventOptionPaymentStepName.CustomerConfirmTransfer,
  );

  const { isTSBP, requisitesBankName, customerBankName, customerBank } =
    useOrderContext();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.payment_details',
  });
  const { requisites, amount, currency } = useMemo(() => order || {}, [order]);
  const currencySymbol = useMemo(
    () => find(currencies, ['code', currency])?.symbol || '',
    [currency, currencies],
  );

  const cardInfo = useMemo(
    () => (requisites as RequisitesCard)?.cardInfo || '',
    [requisites],
  );
  const formattedCardInfo = useMemo(() => {
    if (tradeMethod.paymentType === PaymentType.Card2Card) {
      return cardInfo.replace(/(\d{4})(?=\d)/g, '$1 ');
    }
    return cardInfo;
  }, [cardInfo, tradeMethod.paymentType]);

  const phone = useMemo(
    () => (requisites as RequisitesSBP)?.phone || '',
    [requisites],
  );
  const isCopyAvailable = useMemo(() => !!navigator?.clipboard?.writeText, []);

  const getFirstRequisitesTradeMethodField = useCallback(() => {
    const field = first(tradeMethod?.fields);
    return field ? (requisites as Record<string, string>)[field.name] : '';
  }, [requisites, tradeMethod?.fields]);

  const copyCardInfo = useCallback(() => {
    const requisiteDataToCopy =
      cardInfo || phone || getFirstRequisitesTradeMethodField();
    requisiteDataToCopy && navigator.clipboard.writeText(requisiteDataToCopy);
  }, [cardInfo, getFirstRequisitesTradeMethodField, phone]);

  const bank = useMemo(() => requisites?.bank || '', [requisites?.bank]);
  const countryCode = useMemo(
    () => requisites?.countryCode || '',
    [requisites?.countryCode],
  );

  const requisitesCountry = useMemo(
    () =>
      t(`country.values.${toLower(countryCode)}`, {
        defaultValue: requisites?.countryNameEn || countryCode,
      }),
    [countryCode, requisites?.countryNameEn, t],
  );

  const formattedFields: Field[] = useMemo(
    () =>
      [
        ...tradeMethod.fields,
        ...(bank ? [{ name: 'bank', type: 'bank' }] : []),
        ...(countryCode ? [{ name: 'countryCode', type: 'country' }] : []),
      ]
        .filter((field) => requisites?.[field.name as keyof Requisites]?.trim())
        .map(({ name, type }) => {
          let rawValue = requisites?.[name as keyof Requisites] || '';

          if (
            tradeMethod.paymentType === PaymentType.Card2Card &&
            name === 'cardInfo'
          ) {
            rawValue = formattedCardInfo;
          }

          if (bank && name === 'bank') {
            rawValue = requisitesBankName || '';
          }

          if (countryCode && name === 'countryCode') {
            rawValue = requisitesCountry;
          }

          const value = `${t(`${type}.label`, {
            defaultValue: type,
          })}: ${rawValue}`;

          return {
            name,
            type,
            value,
          };
        }),
    [
      tradeMethod.fields,
      tradeMethod.paymentType,
      bank,
      countryCode,
      requisites,
      t,
      formattedCardInfo,
      requisitesBankName,
      requisitesCountry,
    ],
  );

  const isSberPay = useMemo(
    () => tradeMethod.paymentType === PaymentType.SberPay,
    [tradeMethod.paymentType],
  );

  return (
    <PaymentLayout actions={actions}>
      <div className="tw-w-full">
        <div className="tw-flex tw-items-center tw-w-full tw-mb-5">
          <CountdownTimer
            order={order}
            withPlaceholder
            refetchOrderStatus={refetchOrderStatus}
          />
          <div className="tw-text-primary tw-text-lg md:tw-text-2xl">
            <span className="tw-font-semibold">
              {isTSBP &&
                t('rules_tsbp.title', {
                  bank: customerBankName,
                  amount: order.amount,
                  country: requisitesCountry,
                })}
            </span>
            {!isTSBP && (
              <Fragment>
                {t(isSberPay ? 'open_sberpay' : 'open')}
                <span className="tw-text-secondary">{t('transfer')}</span>
                <span className="tw-text-success tw-font-semibold">{` ${amount} ${currencySymbol} `}</span>
                {!isSberPay && (
                  <span className="tw-font-semibold">{t('requisites')}</span>
                )}
              </Fragment>
            )}
          </div>
        </div>
        {!isSberPay ? (
          <div
            className={cx(
              'tw-flex tw-items-center tw-w-full tw-h-auto tw-mb-5 tw-px-3 tw-py-2 tw-rounded-xl tw-bg-layout-accent tw-border-2 tw-border-layout-accent md:tw-px-7 md:tw-py-4 md:tw-rounded-2xl',
            )}
          >
            <div className="tw-flex tw-flex-col tw-text-md tw-font-bold tw-text-primary md:tw-text-xl">
              {formattedFields.map((field) => (
                <div key={field.name} className="tw-flex tw-mr-5">
                  {field.value}
                </div>
              ))}
            </div>
            {isCopyAvailable && (
              <Button
                className={cx(
                  'tw-flex tw-justify-center tw-items-center tw-self-start !tw-w-10 tw-ml-auto !tw-text-sm !tw-text-copy !tw-rounded-lg md:!tw-w-40 !tw-h-10',
                )}
                color="tertiary"
                onClick={copyCardInfo}
              >
                <SVG
                  src={COPY_ICON_PATH}
                  title="copy"
                  className="tw-w-3.5 tw-h-3.5 md:tw-mr-3"
                />
                <div className="tw-hidden md:tw-block">{t('buttons.copy')}</div>
              </Button>
            )}
          </div>
        ) : (
          <Button
            onClick={() =>
              window.open(
                requisites?.sberPayUrl,
                '_blank',
                'noopener noreferrer',
              )
            }
            color="success"
            className="tw-flex tw-justify-center tw-items-center tw-mb-5 tw-py-6 tw-text-xl md:tw-text-3xl !tw-text-primary"
          >
            <span className="tw-mb-1">{t('pay')}</span>
            <img
              src={`${IMAGES_PATH}sberpay-logo.svg`}
              className="tw-h-full tw-ml-2"
              alt="sberpay-logo"
            />
          </Button>
        )}
        <div className="tw-flex tw-items-start">
          <div className="tw-hidden tw-items-center tw-justify-center tw-min-w-24 tw-w-24 tw-h-24 tw-mr-8 tw-rounded-2xl tw-bg-layout-accent-alt md:tw-flex">
            <SVG
              src={EXCLAMATION_CLOUD_ICON_PATH}
              title="exclamation-icon"
              className="tw-w-9"
            />
          </div>
          <PaymentDetailsRules
            isTSBP={isTSBP}
            customerBank={customerBank}
            customerBankName={customerBankName}
            requisitesCountry={requisitesCountry}
            requisitesBank={bank}
          />
        </div>
      </div>
    </PaymentLayout>
  );
};
