import React, { useCallback, useState } from 'react';

import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { TextField } from './TextField';
import { getTCommon, useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

const REQUIRED_LENGTH = 12;

export const Field: React.FC<FormFieldProps> = ({
  field: { name, value },
  onChange,
}) => {
  const { t } = useTFeature();

  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(
    (value: string) => {
      if (!onChange || value?.length > REQUIRED_LENGTH) {
        return;
      }
      const numericValue = value.replace(/[^0-9#]/g, '');
      setLocalValue(numericValue);
      onChange(numericValue.length === REQUIRED_LENGTH ? numericValue : '');
    },
    [onChange],
  );

  return (
    <TextField
      name={name}
      value={localValue}
      onChange={handleChange}
      placeholder={t('fields.utr.placeholder')}
      hint={t('fields.utr.hint')}
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();
  return getBaseFieldValidator({ required }).matches(
    /^[0-9]{12}$/,
    tCommon('errors.invalid'),
  );
};
