import { LanguageCode } from 'enums';

export const DEFAULT_LANGUAGE = 'en_US';

export const MAP_LANGUAGE_TO_UPPY_LOCALE: Record<
  Partial<LanguageCode>,
  string
> = {
  [LanguageCode.English]: DEFAULT_LANGUAGE,
  [LanguageCode.Russian]: 'ru_RU',
  [LanguageCode.Spanish]: 'es_ES',
  [LanguageCode.Hindi]: 'hi_IN',
  [LanguageCode.Turkish]: 'tr_TR',
  [LanguageCode.Ukrainian]: 'uk_UA',
  [LanguageCode.Uzbek]: 'uz_UZ',
  // TODO provide translations for these languages:
  [LanguageCode.Armenian]: DEFAULT_LANGUAGE,
  [LanguageCode.Azerbaijani]: DEFAULT_LANGUAGE,
  [LanguageCode.Kyrgyz]: DEFAULT_LANGUAGE,
  [LanguageCode.Kazakh]: DEFAULT_LANGUAGE,
  [LanguageCode.Tajik]: DEFAULT_LANGUAGE,
};
