import cx from 'classnames';
import { includes } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { ORDER_STATUS_VIEW_ICONS_PATH } from 'constants/images.constants';
import { OrderStatusView, PaymentType } from 'enums';
import { TranslationNamespace } from 'i18n';

import './PaymentTitle.style.scss';

type Props = {
  paymentType: PaymentType;
  bankName: string;
  stepName: OrderStatusView;
  storeName: string;
};

export const PaymentTitle: React.FC<Props> = ({
  bankName,
  stepName,
  storeName,
  paymentType,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.payment_title',
  });

  const isErrorStatus = includes(
    [OrderStatusView.Cancelled, OrderStatusView.Dispute, OrderStatusView.Error],
    stepName,
  );

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full md:tw-flex-row md:tw-justify-between">
      <div className="tw-flex tw-flex-col tw-items-center md:tw-flex-row md:tw-mr-5">
        <div
          className={cx(
            'tw-flex tw-items-center tw-justify-center tw-flex-none tw-w-20 tw-h-20 tw-mb-6 tw-rounded-xl md:tw-w-20 md:tw-h-20 md:tw-mr-8 md:tw-mb-0 md:tw-rounded-2xl',
            {
              'tw-bg-status-icon': !isErrorStatus,
              'tw-bg-status-error-icon': isErrorStatus,
            },
          )}
        >
          <SVG
            src={ORDER_STATUS_VIEW_ICONS_PATH[stepName]}
            title={`${stepName}-icon`}
            className="tw-w-8 md:tw-w-8"
          />
        </div>
        <div>
          <div className="tw-mb-1 tw-text-xl tw-font-bold tw-text-primary tw-text-center md:tw-mb-2 md:tw-text-2xl md:tw-text-left">
            {t(`${stepName}.title`)}
          </div>
          <div className="tw-text-md tw-text-secondary tw-text-center md:tw-text-xl md:tw-text-left">
            {stepName === OrderStatusView.CustomerConfirmTransfer ? (
              <Fragment>
                {t(`${stepName}.sub_title.${paymentType}`)}
                {bankName && paymentType !== PaymentType.SberPay
                  ? ` (${bankName})`
                  : ''}
              </Fragment>
            ) : (
              t(`${stepName}.sub_title`)
            )}
          </div>
        </div>
      </div>
      <div className="tw-text-md tw-text-secondary tw-text-center tw-mt-4 md:tw-text-xl md:tw-text-right md:tw-mt-0">
        <div>{t('store_prefix')}</div>
        <div>{storeName}</div>
      </div>
    </div>
  );
};
