import cx from 'classnames';
import React from 'react';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'error' | 'info';
  iconPath?: string;
  iconClassName?: string;
}

const colorClassName = {
  primary:
    'tw-text-primary-button tw-bg-primary-button hover:tw-bg-primary-button-hover focus:tw-bg-primary-button-hover active:tw-bg-primary-button-active',
  secondary:
    'tw-text-secondary-button tw-bg-secondary-button hover:tw-bg-secondary-button-hover focus:tw-bg-secondary-button-hover active:tw-bg-secondary-button-active',
  tertiary:
    'tw-text-tertiary-button tw-bg-tertiary-button hover:tw-bg-tertiary-button-hover focus:tw-bg-tertiary-button-hover active:tw-bg-tertiary-button-active',
  success:
    'tw-text-success-button tw-bg-success-button hover:tw-bg-success-button-hover focus:tw-bg-success-button-hover active:tw-bg-success-button-active',
  error:
    'tw-text-error-button tw-bg-error-button hover:tw-bg-error-button-hover focus:tw-bg-error-button-hover active:tw-bg-error-button-active tw-border tw-border-error-button',
  info: 'tw-text-info-button tw-bg-info-button hover:tw-bg-info-button-hover focus:tw-bg-info-button-hover active:tw-bg-info-button-active',
};

const disabledStyle =
  'disabled:tw-bg-disabled-button disabled:tw-text-disabled-button';

export const Button: React.FC<Props> = ({
  color = 'primary',
  children,
  className = '',
  iconPath,
  iconClassName = '',
  ...rest
}: Props) => (
  <button
    className={cx(
      'tw-relative tw-w-full tw-h-16 tw-rounded-xl tw-font-semibold tw-transition-colors tw-duration-200 tw-ease-in tw-outline-none tw-select-none md:tw-h-20 md:tw-rounded-2xl',
      colorClassName[color],
      disabledStyle,
      {
        'tw-flex tw-justify-center tw-items-center': !!iconPath,
      },
      className,
    )}
    {...rest}
  >
    {iconPath && (
      <img
        className={cx('tw-w-4 tw-mr-2', iconClassName)}
        src={iconPath}
        alt="button-icon"
      />
    )}
    {children}
  </button>
);
