import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/meta`;

const getAll = async () => (await axios.get(url)).data;

export const metaApi = {
  getAll,
};
