import React from 'react';

type Props = {
  title?: string;
  description?: string;
  icon: {
    src: string;
    alt: string;
  };
};

export const InfoCard: React.FC<Props> = ({ icon, title, description }) => (
  <div className="tw-relative tw-w-full tw-max-w-3xl tw-m-auto tw-py-12 tw-text-primary tw-bg-component tw-rounded-2xl tw-text-center md:tw-pt-24 md:tw-pb-14">
    <img
      className="tw-absolute -tw-top-10 tw-left-1/2 -tw-translate-x-1/2 tw-w-20 md:tw-w-28"
      src={icon.src}
      alt={icon.alt}
    />
    {title && <div className="tw-text-9xl">{title}</div>}
    {description && (
      <div className="tw-text-2xl tw-text-secondary tw-mt-6">{description}</div>
    )}
  </div>
);
