const readEnvVariable = (key: keyof typeof window.__env) =>
  process.env[key] || window.__env?.[key];

const publicUrl = readEnvVariable('PUBLIC_URL');

export const env = {
  apiUrl: `${readEnvVariable('REACT_APP_API_HOST')}/api`,
  assetsPrefix: publicUrl,
  publicUrl,
  gaMeasurementId: readEnvVariable('REACT_APP_GA_MEASUREMENT_ID'),
};
