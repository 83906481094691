import { useEffect } from 'react';

import { EventName } from '../enums';
import { gaUtils } from '../utils';

export const useTrackEvent = (eventName: EventName, options?: any) => {
  useEffect(() => {
    gaUtils.trackEvent(eventName, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
