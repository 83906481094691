import React, { Fragment, useCallback } from 'react';

type Props = {
  name: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  hint?: string;
};

export const TextField: React.FC<Props> = ({
  name,
  value,
  placeholder,
  autoFocus,
  onChange,
  hint,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange],
  );

  return (
    <Fragment>
      <input
        className="tw-block tw-w-full tw-h-14 tw-px-4 tw-text-sm tw-rounded-xl tw-bg-primary-input md:tw-h-20 md:tw-px-6 md:tw-rounded-2xl tw-text-primary-input md:tw-text-xl md:tw-leading-8"
        name={name}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={handleChange}
      />
      {hint && (
        <div className="tw-pl-2 tw-text-secondary">
          {' * '}
          {hint}
        </div>
      )}
    </Fragment>
  );
};
