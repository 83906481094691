import { filter, some, sortBy, startsWith, transform, without } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentOption } from 'components';
import { ERROR_ICON_PATH } from 'constants/images.constants';
import { EventOptionPaymentStepName } from 'enums';
import { useTrackPaymentStepDuration } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethod } from 'types';

const TRADE_METHOD_TO_COMBINE = ['sbp', 'tsbp'];
const ANY_BANK_PREFIX = 'any';

type Props = {
  tradeMethods?: TradeMethod[];
  handleSelect: (tradeMethod: TradeMethod) => void;
};

export const PaymentRules: React.FC<Props> = ({
  tradeMethods = [],
  handleSelect,
}) => {
  useTrackPaymentStepDuration(EventOptionPaymentStepName.New);

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.bank_select',
  });

  //todo: move to service or even server logic
  const formattedTradeMethods = useMemo(() => {
    const sortedTraderMethods = sortBy(tradeMethods, [
      'paymentTypeName',
      'bankName',
    ]);
    const anyBankTradeMethods = filter(
      sortedTraderMethods,
      (tradeMethod: TradeMethod) =>
        startsWith(tradeMethod.bank, ANY_BANK_PREFIX),
    );
    const transformedTradeMethods = transform<TradeMethod, TradeMethod[]>(
      without(sortedTraderMethods, ...anyBankTradeMethods),
      (acc, tradeMethod: TradeMethod) => {
        if (TRADE_METHOD_TO_COMBINE.includes(tradeMethod.paymentType)) {
          if (!some(acc, { paymentType: tradeMethod.paymentType })) {
            acc.push({
              ...tradeMethod,
              bankName: null as any as string,
              bank: null as any as string,
            });
          }
        } else {
          acc.push(tradeMethod);
        }
      },
      [],
    );
    return [...anyBankTradeMethods, ...transformedTradeMethods];
  }, [tradeMethods]);

  if (formattedTradeMethods.length === 0) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-bg-payment-layout-out tw-py-4 tw-px-8 tw-rounded-xl">
        <img className="tw-w-8 tw-mr-4" src={ERROR_ICON_PATH} alt="error" />
        <div className="tw-text-secondary tw-text-lg">
          {t('errors.no_methods_available')}
        </div>
      </div>
    );
  }

  return (
    <div className="tw-w-full tw-grid tw-gap-8 tw-grid-cols-1 md:tw-gap-x-5 md:tw-gap-y-16 md:tw-grid-cols-2 lg:tw-grid-cols-3">
      {formattedTradeMethods.map((tradeMethod: TradeMethod) => {
        const { paymentType, bank, bankName, paymentTypeName, fiatCurrency } =
          tradeMethod;

        return (
          <PaymentOption
            bank={bank}
            bankName={bankName}
            paymentType={paymentType}
            paymentTypeName={paymentTypeName}
            onSelect={() => handleSelect(tradeMethod)}
            key={paymentType + bank + fiatCurrency}
          />
        );
      })}
    </div>
  );
};
