import React from 'react';

import { FormFieldProps, GetFieldValidatorFn } from 'types';

import { TextField } from './TextField';
import { useTFeature } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

export const Field: React.FC<FormFieldProps> = ({
  field: { name, value },
  autoFocus,
  onChange,
}) => {
  const { t } = useTFeature();
  return (
    <TextField
      name={name}
      value={value}
      autoFocus={autoFocus}
      placeholder={t('fields.account_number.placeholder')}
      onChange={onChange}
    />
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) =>
  getBaseFieldValidator({ required });
