export enum LanguageCode {
  English = 'en',
  Russian = 'ru',
  Armenian = 'am',
  Azerbaijani = 'az',
  Spanish = 'es',
  Hindi = 'hi',
  Kyrgyz = 'kg',
  Kazakh = 'kz',
  Tajik = 'tj',
  Turkish = 'tr',
  Ukrainian = 'ua',
  Uzbek = 'uz',
}
