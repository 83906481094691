import { useTranslation } from 'react-i18next';

import { InfoCard } from 'components';
import { ERROR_ICON_PATH } from 'constants/images.constants';
import { TranslationNamespace } from 'i18n';

export const ErrorFallback = () => {
  const { t } = useTranslation(TranslationNamespace.Common);
  return (
    <InfoCard
      description={t('components.error_fallback.title')}
      icon={{
        src: ERROR_ICON_PATH,
        alt: 'error',
      }}
    />
  );
};
