import { FormFieldType } from 'enums';
import { FormFieldCombined, MultiFormFieldCombined } from 'types';

import {
  Bank,
  LastPhoneDigits,
  FullName,
  CardDigits,
  UTR,
  IBAN,
  AccountNumber,
} from './fields';

export const fieldsList: Partial<Record<FormFieldType, FormFieldCombined>> = {
  [FormFieldType.AccountNumber]: AccountNumber,
  [FormFieldType.Bank]: Bank,
  [FormFieldType.LastPhoneDigits]: LastPhoneDigits,
  [FormFieldType.FullName]: FullName,
  [FormFieldType.UTR]: UTR,
  [FormFieldType.IBAN]: IBAN,
};

export const multiFieldsList: Partial<
  Record<FormFieldType, MultiFormFieldCombined>
> = {
  [FormFieldType.FirstCardDigits]: CardDigits,
  [FormFieldType.LastCardDigits]: CardDigits,
};
