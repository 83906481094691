import { env } from 'config';
import { OrderStatusView } from 'enums';

export const IMAGES_PATH = `${env.assetsPrefix}/assets/images/`;
export const APP_LOGO_PATH = `${IMAGES_PATH}logo.svg`;
export const APP_LOGO_LARGE_PATH = `${IMAGES_PATH}logo-large.svg`;
export const CROSS_ICON_PATH = `${IMAGES_PATH}cross.svg`;
export const CHECK_ICON_PATH = `${IMAGES_PATH}check.svg`;
export const EXCLAMATION_CLOUD_ICON_PATH = `${IMAGES_PATH}exclamation-cloud.svg`;
export const MOBILE_HAND_PATH = `${IMAGES_PATH}mobile-hand.png`;
export const COPY_ICON_PATH = `${IMAGES_PATH}copy.svg`;
export const IMAGE_ICON_PATH = `${IMAGES_PATH}image-icon.svg`;
export const TELEGRAM_ICON_PATH = `${IMAGES_PATH}telegram.svg`;
export const SUCCESS_ICON_PATH = `${IMAGES_PATH}step-success.svg`;
export const ERROR_ICON_PATH = `${IMAGES_PATH}step-error.svg`;
export const LOADING_ICON_PATH = `${IMAGES_PATH}step-loading.svg`;
export const ORDER_STATUS_VIEW_ICONS_PATH = {
  [OrderStatusView.New]: `${IMAGES_PATH}step-card-select.svg`,
  [OrderStatusView.Requisites]: `${IMAGES_PATH}step-loading.svg`,
  [OrderStatusView.CustomerConfirm]: `${IMAGES_PATH}step-card.svg`,
  [OrderStatusView.CustomerConfirmCard]: `${IMAGES_PATH}step-card.svg`,
  [OrderStatusView.CustomerConfirmTransfer]: `${IMAGES_PATH}step-card.svg`,
  [OrderStatusView.TraderConfirm]: `${IMAGES_PATH}step-card.svg`,
  [OrderStatusView.Completed]: `${IMAGES_PATH}step-success.svg`,
  [OrderStatusView.Cancelled]: `${IMAGES_PATH}step-error.svg`,
  [OrderStatusView.Error]: `${IMAGES_PATH}step-error.svg`,
  [OrderStatusView.Dispute]: `${IMAGES_PATH}step-error.svg`,
};
