import cx from 'classnames';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentLayout, PaymentLayoutAction } from 'components/index';
import { EventName, OrderStatus, PaymentStatusView } from 'enums';
import { useTrackEvent } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { ICON_CONFIG } from './constants';

type Props = PropsWithChildren<{
  orderStatus: OrderStatus;
  receiptsAttached: boolean;
  actions: PaymentLayoutAction[];
}>;

export const PaymentStatusLayout: React.FC<Props> = ({
  orderStatus,
  receiptsAttached,
  children,
  actions,
}) => {
  const type: PaymentStatusView = useMemo(() => {
    if (orderStatus === OrderStatus.Dispute) {
      return receiptsAttached
        ? PaymentStatusView.Processing
        : PaymentStatusView.Dispute;
    }

    if (orderStatus === OrderStatus.Cancelled) {
      return PaymentStatusView.Cancel;
    }

    return PaymentStatusView.Success;
  }, [orderStatus, receiptsAttached]);

  useTrackEvent(EventName.PaymentFlowComplete, {
    type,
  });

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: `components.payment_${type}`,
  });

  const { iconPath, iconBg } = useMemo(() => ICON_CONFIG[type], [type]);

  return (
    <PaymentLayout
      titleClassName="tw-relative tw-pt-20 md:tw-pt-40"
      actions={actions}
    >
      <div
        className={cx(
          'tw-absolute -tw-top-6 tw-left-1/2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-20 tw-h-20 tw-rounded-full -tw-translate-x-1/2 md:tw-w-40 md:tw-h-40',
          iconBg,
        )}
      >
        <img className="tw-w-8 md:tw-w-16" src={iconPath} alt="check-icon" />
      </div>
      <div>
        <div className="tw-mb-2 tw-text-xl tw-text-primary tw-text-center md:tw-mb-5 md:tw-text-3xl">
          {t('title')}
        </div>
        <div className="tw-mb-10 tw-text-lg tw-text-secondary tw-text-center last-of-type:tw-mb-0 md:tw-text-xl">
          {t('message')}
        </div>
        {children}
      </div>
    </PaymentLayout>
  );
};
