import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { PaymentLayoutAction } from 'components';
import { OrderStatus } from 'enums';
import { Order, OrderReceipt } from 'types';

import { PaymentReceiptsDispute } from './PaymentReceiptsDispute';
import { PaymentStatusLayout } from './PaymentStatusLayout';

type Props = {
  order: Order;
  telegramSupportBotUrl?: string;
  actions: PaymentLayoutAction[];
  receipts?: OrderReceipt[];
  onReceiptsUpload: () => void;
};

export const PaymentStatus: React.FC<Props> = ({
  order,
  telegramSupportBotUrl,
  actions,
  onReceiptsUpload,
  receipts,
}) => {
  const receiptsEnabled = useMemo(
    () =>
      order.status === OrderStatus.Cancelled ||
      order.status === OrderStatus.Dispute,
    [order.status],
  );

  return (
    <PaymentStatusLayout
      orderStatus={order.status}
      receiptsAttached={!isEmpty(receipts)}
      actions={actions}
    >
      {receiptsEnabled && (
        <PaymentReceiptsDispute
          order={order}
          receipts={receipts}
          telegramSupportBotUrl={telegramSupportBotUrl}
          onReceiptsUpload={onReceiptsUpload}
        />
      )}
    </PaymentStatusLayout>
  );
};
