import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { PageLayout, SciDomainProvider } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { OrderPage, HelpPage, NotFoundPage } from 'pages';

export const AppRoutes: React.FC = () => (
  <SciDomainProvider>
    <Routes>
      <Route element={<PageLayout />}>
        <Route path={ROUTE_PATH.ORDER} element={<OrderPage />} />
        <Route path={ROUTE_PATH.HELP} element={<HelpPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </SciDomainProvider>
);
