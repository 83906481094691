import { find } from 'lodash';
import React, { useMemo } from 'react';

import {
  PaymentLayout,
  PaymentLayoutAction,
  CountdownTimer,
  CardImage,
  LogoImage,
} from 'components';
import { EventOptionPaymentStepName } from 'enums';
import { FormFields } from 'features/form-builder';
import { useTrackPaymentStepDuration } from 'hooks';
import { Bank, FormField, Order, PaymentFormValues, TradeMethod } from 'types';

type Props = {
  order: Order;
  banks: Array<Bank>;
  tradeMethods?: Array<TradeMethod>;
  paymentFormValues?: PaymentFormValues;
  onPaymentFormValuesChange: (data: PaymentFormValues) => void;
  refetchOrderStatus: () => void;
  actions: PaymentLayoutAction[];
  customerFields: FormField[];
};

export const PaymentCard: React.FC<Props> = ({
  order,
  banks,
  tradeMethods,
  paymentFormValues,
  onPaymentFormValuesChange,
  refetchOrderStatus,
  actions,
  customerFields,
}) => {
  useTrackPaymentStepDuration(
    EventOptionPaymentStepName.CustomerConfirmDetails,
  );

  const paymentType = useMemo(
    () => order?.payment?.type,
    [order?.payment?.type],
  );
  const paymentTypeName =
    useMemo(
      () =>
        find(
          tradeMethods,
          (tradeMethod) => tradeMethod.paymentType === paymentType,
        )?.paymentTypeName,
      [paymentType, tradeMethods],
    ) || '';
  const bank = useMemo(() => order?.payment?.bank, [order?.payment?.bank]);
  const bankName =
    useMemo(
      () => find(banks, ({ code }) => code === bank)?.name,
      [bank, banks],
    ) || '';

  return (
    <PaymentLayout actions={actions}>
      <div className="tw-relative tw-w-full tw-pt-28 md:tw-pt-44">
        <CardImage
          bank={bank}
          bankName={bankName}
          paymentType={paymentType}
          paymentTypeName={paymentTypeName}
          className="tw-absolute -tw-top-12 tw-left-1/2 tw-h-24 tw-w-auto -tw-translate-x-1/2 tw-rounded-lg md:-tw-top-24 md:tw-h-52"
        />
        <LogoImage
          bank={bank}
          bankName={bankName}
          paymentType={paymentType}
          paymentTypeName={paymentTypeName}
          className="tw-absolute tw-right-0 tw-top-20 tw-block tw-overflow-hidden	tw-text-ellipsis tw-max-h-8 tw-max-w-card-logo-large tw-text-base tw-leading-5 tw-text-logo-font -tw-translate-y-1/2 md:tw-top-32 md:tw-max-h-10 md:tw-max-w-card-logo-large-md"
        />
        <div className="tw-absolute tw-left-0 tw-top-10 md:tw-top-16">
          <CountdownTimer
            order={order}
            refetchOrderStatus={refetchOrderStatus}
          />
        </div>
        <FormFields
          fields={customerFields}
          paymentFormValues={paymentFormValues}
          onPaymentFormValuesChange={onPaymentFormValuesChange}
        />
      </div>
    </PaymentLayout>
  );
};
