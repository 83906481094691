export enum FormFieldType {
  AccountNumber = 'account_number',
  LastCardDigits = 'last_card_digits',
  FirstCardDigits = 'first_card_digits',
  Bank = 'bank',
  LastPhoneDigits = 'last_phone_digits',
  FullName = 'full_name',
  UTR = 'utr',
  IBAN = 'customer_iban',
}
