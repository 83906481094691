import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES, LANGUAGE_FLAGS } from 'constants/languages.constants';

const LANGUAGE_SELECTOR_ID = 'language-selector';

const getFlagSuffix = (language: string) =>
  LANGUAGE_FLAGS[language] || language;

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isLangSelectorOpen, setIsLangSelectorOpen] = useState(false);

  const selectedLanguage = useMemo(
    () => i18n.resolvedLanguage,
    [i18n.resolvedLanguage],
  );

  useEffect(() => {
    const onWindowClick = (event: any) => {
      const target = event.target.closest('button');
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setIsLangSelectorOpen(false);
    };
    window.addEventListener('click', onWindowClick);

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const onToggleLanguageSelector = useCallback(() => {
    setIsLangSelectorOpen(!isLangSelectorOpen);
  }, [isLangSelectorOpen]);

  const onSelectLanguage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const {
        currentTarget: { id },
      } = event;

      i18n.changeLanguage(id);
    },
    [i18n],
  );

  return (
    <div className="tw-relative tw-flex tw-items-center tw-h-full tw-px-1">
      <button
        id={LANGUAGE_SELECTOR_ID}
        onClick={onToggleLanguageSelector}
        className="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full focus:tw-bg-component hover:tw-bg-component hover:tw-cursor-pointer"
      >
        <span className={`fi fi-${getFlagSuffix(selectedLanguage)}`} />
      </button>
      {isLangSelectorOpen && (
        <div className="tw-absolute tw-flex tw-flex-col tw-top-full tw-right-0 tw-w-auto tw-h-auto tw-max-h-34 tw-p-1 tw-bg-payment-layout tw-rounded-lg tw-z-10">
          {LANGUAGES.map(({ code }) => (
            <button
              onClick={onSelectLanguage}
              className="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-full hover:tw-bg-component  hover:tw-cursor-pointer"
              id={code}
              key={code}
            >
              <span className={`fi fi-${getFlagSuffix(code)}`} />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
