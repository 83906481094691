import React from 'react';
import SVG from 'react-inlinesvg';

import { IMAGES_PATH } from 'constants/images.constants';
import { PaymentType } from 'enums';

import './LogoImage.style.scss';

type Props = {
  bank: string;
  bankName: string;
  paymentType?: string;
  paymentTypeName?: string;
  className?: string;
};

const SPECIAL_LOGOS: Record<string, string> = {
  [PaymentType.SberPay]: 'sberpay-logo.svg',
  [PaymentType.TSBP]: 'tsbp-logo.svg',
};

export const LogoImage: React.FC<Props> = ({
  bank,
  bankName,
  paymentType = '',
  paymentTypeName,
  ...rest
}) => {
  const logo = SPECIAL_LOGOS[paymentType] || `${bank || paymentType}-logo.svg`;

  const title = `${paymentTypeName} ${bankName}`;

  return (
    <SVG src={`${IMAGES_PATH}logos/${logo}`} title={title} {...rest}>
      <span {...rest}>{bankName}</span>
    </SVG>
  );
};
