export enum SciTheme {
  sci = 'sci',
  payforu = 'payforu',
  light = 'light',
}

export enum SciThemeToUppyDialogMap {
  sci = 'dark',
  payforu = 'dark',
  light = 'light',
}
