import ReactGA from 'react-ga4';

import { env } from 'config';
import { EventName } from 'enums';

const initialize = () => {
  const trackingID = env.gaMeasurementId;
  if (!trackingID) {
    return;
  }

  ReactGA.initialize(trackingID, {
    gtagOptions: {
      send_page_view: false,
    },
  });
};

const trackEvent = (eventName: EventName, options?: any) => {
  if (!ReactGA.isInitialized) {
    return;
  }

  ReactGA.event(eventName, options);
};

const trackException = (options?: { fatal: boolean; description: string }) => {
  trackEvent(EventName.Exception, options);
};

export const gaUtils = {
  initialize,
  trackEvent,
  trackException,
};
