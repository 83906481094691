import React, { useCallback, useMemo, useState } from 'react';

import { FormFieldProps } from 'types';

import { TextField } from './TextField';
import { getTCommon, isValidString, useTFeature } from '../fields.utils';

const IBAN_REGEX = /^[A-Z]{2}[A-Z0-9]{4,30}$/;
const IBAN_MAX_LENGTH = 32;

export const Field: React.FC<FormFieldProps> = ({
  field: { name, value, pattern, patternExample, maxLength },
  onChange,
}) => {
  const { t } = useTFeature();
  const tCommon = getTCommon();

  const [localValue, setLocalValue] = useState(value);

  const ibanRegex = useMemo(
    () => (pattern ? new RegExp(pattern) : IBAN_REGEX),
    [pattern],
  );

  const ibanMaxLength = useMemo(
    () => maxLength || IBAN_MAX_LENGTH,
    [maxLength],
  );

  const handleChange = useCallback(
    (value: string) => {
      if (!onChange || value?.length > ibanMaxLength) {
        return;
      }

      setLocalValue(value);
      onChange(isValidString(value, ibanRegex) ? value : '');
    },
    [ibanMaxLength, ibanRegex, onChange],
  );

  return (
    <TextField
      name={name}
      value={localValue}
      onChange={handleChange}
      placeholder={t('fields.iban.placeholder')}
      hint={`${tCommon('common.example')}: ${patternExample}`}
    />
  );
};
