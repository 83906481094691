import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/customer/auth`;
export const LOGIN_URL = `${url}/login`;

const login = async (data: { orderId: string; token: string }) =>
  (await axios.post(LOGIN_URL, data)).data;

export const authApi = {
  login,
};
