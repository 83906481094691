import { AxiosError } from 'axios';
import { filter, find, isEmpty, isNil, map, omitBy, reduce } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  authApi,
  banksApi,
  currencyApi,
  metaApi,
  ordersApi,
  tradeMethodsApi,
} from 'api';
import {
  Layout,
  PaymentCard,
  PaymentDetails,
  PaymentLayoutAction,
  PaymentLoading,
  PaymentRules,
  PaymentStatus,
  UploadReceiptDialog,
  useSciDomain,
} from 'components';
import { OrderContext, OrderContextProps } from 'context';
import {
  CustomerConfirmStatusDetails,
  CustomerDataCollectionOrder,
  EventName,
  EventOptionClickAction,
  FormFieldType,
  OrderCallbackUrlStatus,
  OrderStatus,
  PaymentType,
  QueryKey,
  StatusCode,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { NotFoundPage } from 'pages/NotFound';
import { Order, PaymentFormValues, TradeMethod, UpdateOrderDto } from 'types';
import { authUtils, gaUtils } from 'utils';

const COMPLETED_ORDER_STATUSES = [OrderStatus.Cancelled, OrderStatus.Completed];

const CUSTOMER_FIELDS: Array<keyof PaymentFormValues> = [
  'customerCardFirstDigits',
  'customerCardLastDigits',
  'customerName',
  'customerBank',
  'customerPhoneLastDigits',
  'customerUtr',
  'customerIBAN',
  'customerAccountNumber',
];

export const OrderPage: React.FC = () => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'pages.order',
  });
  const { t: tUploadReceipt } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.upload_confirmation_receipt',
  });

  const params = useParams<{ id: string; token: string }>();
  const authData = useMemo(
    () => ({ orderId: params.id as string, token: params.token as string }),
    [params],
  );
  const { orderId } = useMemo(() => authData, [authData]);
  const [timeoutRefetch, setTimeoutRefetch] = useState(false);
  const [paymentFormValues, setPaymentFormValues] = useState<PaymentFormValues>(
    {
      customerCardFirstDigits: null,
      customerCardLastDigits: null,
      customerName: null,
      customerBank: null,
      customerPhoneLastDigits: null,
      customerUtr: null,
      customerIBAN: null,
      customerAccountNumber: null,
    },
  );
  const [order, setOrder] = useState<Order>();
  const [initialOrderFetched, setInitialOrderFetched] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [selectedTradeMethod, setSelectedTradeMethod] = useState<TradeMethod>();
  const [
    enableSelectedTradeMethodFallback,
    setEnableSelectedTradeMethodFallback,
  ] = useState(false);

  const status = useMemo(() => order?.status as OrderStatus, [order]);

  const isCallbackUrlStatusInProgress = useMemo(
    () =>
      order?.integration?.callbackUrlStatus ===
      OrderCallbackUrlStatus.InProgress,
    [order],
  );

  const shouldRefetch = useMemo(() => {
    if ([OrderStatus.Requisites].includes(status)) {
      return !order?.requisites;
    } else if (
      [
        OrderStatus.CustomerConfirm,
        OrderStatus.TraderConfirm,
        OrderStatus.Dispute,
      ].includes(status)
    ) {
      return true;
    } else if (COMPLETED_ORDER_STATUSES.includes(status)) {
      return isCallbackUrlStatusInProgress;
    }
    return false;
  }, [isCallbackUrlStatusInProgress, order?.requisites, status]);

  const { isLoading: isAuthLoading } = useQuery(
    [QueryKey.Login, authData],
    () => authApi.login(authData),
    {
      enabled: !authenticated && !authenticationFailed,
      retry: false,
      onSuccess: (response: { accessToken: string }) => {
        authUtils.setAuthData(authData);
        authUtils.setAccessToken(response?.accessToken);
        setAuthenticated(true);
      },
      onError: () => {
        setAuthenticationFailed(true);
      },
    },
  );

  const handleError = useCallback((error: any) => {
    setAuthenticationFailed(true);
  }, []);

  const { isLoading: isOrderLoading } = useQuery(
    [QueryKey.Order],
    () => ordersApi.getOne(orderId),
    {
      enabled: authenticated && !initialOrderFetched,
      onSuccess: setOrder,
      onError: handleError,
      onSettled: () => {
        setInitialOrderFetched(true);
      },
    },
  );

  const { isLoading: isBanksLoading, data: banks } = useQuery(
    QueryKey.Banks,
    banksApi.getAll,
    { enabled: !!order, staleTime: Infinity },
  );
  const { isLoading: isCurrenciesLoading, data: currencies } = useQuery(
    QueryKey.Currencies,
    currencyApi.getFiat,
    { enabled: !!order, staleTime: Infinity },
  );
  const { isLoading: isMetaLoading, data: meta } = useQuery(
    QueryKey.Meta,
    metaApi.getAll,
    { enabled: !!order, staleTime: Infinity },
  );
  const telegramSupportBotUrl = useMemo(
    () => find(meta, { key: 'telegram_support_bot_url' })?.value,
    [meta],
  );
  const { isLoading: isTradeMethodsLoading, data: tradeMethods } = useQuery<
    TradeMethod[]
  >(QueryKey.TradeMethods, () => tradeMethodsApi.getAll((order as Order)?.id), {
    enabled: !!order && authenticated,
    staleTime: Infinity,
  });

  const apiActionOptions = useMemo(
    () => ({
      onSuccess: (data: Order) => {
        setOrder(data);
        const updatedValues = reduce(
          CUSTOMER_FIELDS,
          (result, field) => {
            result[field] = paymentFormValues[field] || data?.payment?.[field];
            return result;
          },
          {} as Partial<PaymentFormValues>,
        );
        setPaymentFormValues(updatedValues);
      },
      // try to refresh order as it can be moved to another status e.g. dispute
      onError: (error: AxiosError) => {
        if (error?.response?.status === StatusCode.Unauthorized) {
          setAuthenticationFailed(true);
        } else {
          setInitialOrderFetched(false);
        }
      },
    }),
    [paymentFormValues],
  );

  const queryResultOrder = useQuery(
    QueryKey.Order,
    () => ordersApi.getOne(orderId),
    {
      enabled: shouldRefetch,
      refetchInterval: 3000,
      ...apiActionOptions,
    },
  );

  useQuery(QueryKey.Order, () => ordersApi.getOne(orderId), {
    enabled: timeoutRefetch,
    ...apiActionOptions,
  });

  const { data: paymentFields } = useQuery(
    QueryKey.OrderPaymentFields,
    () => ordersApi.getPaymentFields(orderId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: order?.status === OrderStatus.CustomerConfirm,
    },
  );

  const { mutate: update } = useMutation(ordersApi.update, apiActionOptions);

  const { mutate: startPayment } = useMutation(
    ordersApi.startPayment,
    apiActionOptions,
  );
  const { mutate: confirmPayment } = useMutation(
    ordersApi.confirmPayment,
    apiActionOptions,
  );

  const { mutate: cancel } = useMutation(ordersApi.cancel, apiActionOptions);

  const updatePaymentInfo = useCallback(
    (
      updateDto: UpdateOrderDto,
      { onSuccess }: { onSuccess?: () => void } = {},
    ) => {
      update(
        { id: orderId, data: updateDto },
        {
          onSuccess: (data) => {
            apiActionOptions.onSuccess(data);
            onSuccess?.();
          },
          onError: apiActionOptions.onError,
        },
      );
    },
    [orderId, update, apiActionOptions],
  );

  const returnUrl = useMemo(
    () => order?.integration?.returnUrl,
    [order?.integration?.returnUrl],
  );

  const navigateReturnUrl = useCallback(() => {
    if (returnUrl) {
      window.location.href = returnUrl;
    }
  }, [returnUrl]);

  const selectPaymentType = useCallback(
    (tradeMethod: TradeMethod) => {
      updatePaymentInfo(
        {
          payment: {
            type: tradeMethod.paymentType,
            bank: tradeMethod.bank,
          },
        },
        { onSuccess: () => startPayment(orderId) },
      );
    },
    [orderId, startPayment, updatePaymentInfo],
  );

  const { data: selectedTradeMethodsFallback } = useQuery<TradeMethod>(
    QueryKey.SelectedTradeMethodsFallback,
    () => tradeMethodsApi.getSelectedTradeMethod((order as Order)?.id),
    {
      enabled: !!order && enableSelectedTradeMethodFallback && authenticated,
      staleTime: Infinity,
    },
  );

  const { data: receipts, isLoading: isReceiptsLoading } = useQuery(
    QueryKey.OrderReceipts,
    () => ordersApi.getOrderReceipts(orderId),
    {
      enabled: !!order,
      retry: false,
      onError: apiActionOptions.onError,
    },
  );

  useEffect(() => {
    if (
      order?.payment?.type &&
      order?.status !== OrderStatus.New &&
      tradeMethods
    ) {
      const selectedTradeMethodsLoaded = find(
        tradeMethods,
        ({ paymentType, bank }) =>
          paymentType === order?.payment?.type &&
          (!order?.payment?.bank || bank === order?.payment?.bank),
      );
      const tradeMethod =
        selectedTradeMethodsLoaded || selectedTradeMethodsFallback;

      if (tradeMethod) {
        setSelectedTradeMethod(tradeMethod);
      } else if (!enableSelectedTradeMethodFallback) {
        setEnableSelectedTradeMethodFallback(true);
      }
    }
  }, [
    order,
    order?.payment?.type,
    order?.payment?.bank,
    order?.status,
    tradeMethods,
    setSelectedTradeMethod,
    selectedTradeMethodsFallback,
    enableSelectedTradeMethodFallback,
  ]);

  const customerFields = useMemo(() => {
    if (!selectedTradeMethod) {
      return [];
    }

    return filter(
      map(selectedTradeMethod.customerFields, (field) => {
        const override = find(paymentFields, { name: field.name });
        return {
          ...field,
          ...override,
        };
      }),
      (field) => !field.hidden,
    );
  }, [paymentFields, selectedTradeMethod]);

  const requiredCustomerFields = useMemo(() => {
    if (isEmpty(customerFields)) {
      return [];
    }
    return customerFields
      .filter((field) => field.required)
      .map((field) => field.name) as Array<keyof PaymentFormValues>;
  }, [customerFields]);

  const isCustomerDataConfirmed = useMemo(() => {
    if (!order?.payment?.type && !selectedTradeMethod) {
      return false;
    }
    return requiredCustomerFields.every((field) => order?.payment?.[field]);
  }, [order?.payment, requiredCustomerFields, selectedTradeMethod]);

  const isCardInfoConfirmDisabled = useMemo(() => {
    if (!selectedTradeMethod) {
      return false;
    }
    return requiredCustomerFields.some((field) => !paymentFormValues[field]);
  }, [requiredCustomerFields, paymentFormValues, selectedTradeMethod]);

  const customerDataBeforePayment = useMemo(
    () =>
      order?.shop.customerDataCollectionOrder ===
      CustomerDataCollectionOrder.BeforePayment,
    [order?.shop.customerDataCollectionOrder],
  );

  const refetchOrder = useCallback(
    // Order may be in 'cancelled' status -> query may be `enabled: false` -> invalidate won't work -> manually trigger refetch
    () => queryResultOrder.refetch(),
    [queryResultOrder],
  );

  const [uploadReceiptModalOpen, setUploadReceiptModalOpen] = useState(false);

  const uploadModalLocaleOverride = useMemo(
    () => ({
      dropPasteImportFiles: tUploadReceipt('description'),
      uploadXFiles: {
        0: tUploadReceipt('submit'),
      },
    }),
    [tUploadReceipt],
  );

  const confirmCard = useCallback(
    (formValues: PaymentFormValues) => {
      gaUtils.trackEvent(EventName.Click, {
        action: EventOptionClickAction.ConfirmCustomerDetails,
      });
      updatePaymentInfo(
        {
          payment: omitBy(formValues, isNil),
        },
        customerDataBeforePayment
          ? {}
          : { onSuccess: () => confirmPayment(orderId) },
      );
    },
    [confirmPayment, customerDataBeforePayment, orderId, updatePaymentInfo],
  );

  const actions: Record<string, PaymentLayoutAction> = useMemo(
    () => ({
      cancel: {
        label: t('buttons.cancel'),
        color: 'error',
        onClick: () => {
          gaUtils.trackEvent(EventName.Click, {
            action: EventOptionClickAction.OrderCancel,
          });
          cancel(orderId);
        },
      },
      confirmCard: {
        label: t('buttons.confirm_card'),
        color: 'primary',
        disabled: isCardInfoConfirmDisabled,
        onClick: () => {
          confirmCard(paymentFormValues);
        },
      },
      confirmPayment: {
        label: t('buttons.confirm_transfer'),
        color: 'success',
        onClick: () => {
          gaUtils.trackEvent(EventName.Click, {
            action: EventOptionClickAction.ConfirmTransfer,
          });
          updatePaymentInfo(
            {
              customerConfirmStatusDetails:
                CustomerConfirmStatusDetails.CustomerPayed,
            },
            customerDataBeforePayment
              ? {
                  onSuccess: () => {
                    if (order?.shop?.collectCustomerReceipts) {
                      setUploadReceiptModalOpen(true);
                    } else {
                      confirmPayment(orderId);
                    }
                  },
                }
              : {},
          );
        },
      },
      backToShop: {
        color: 'tertiary',
        label: t('buttons.back_to_shop'),
        onClick: () => {
          gaUtils.trackEvent(EventName.Click, {
            action: EventOptionClickAction.BackToShop,
          });
          navigateReturnUrl();
        },
        hidden: !returnUrl,
      },
    }),
    [
      t,
      isCardInfoConfirmDisabled,
      returnUrl,
      cancel,
      orderId,
      confirmCard,
      paymentFormValues,
      updatePaymentInfo,
      customerDataBeforePayment,
      order?.shop?.collectCustomerReceipts,
      confirmPayment,
      navigateReturnUrl,
    ],
  );

  const isLoading = useMemo(
    () =>
      (!authenticated && isAuthLoading) ||
      (!order && isOrderLoading) ||
      isBanksLoading ||
      isCurrenciesLoading ||
      isMetaLoading ||
      isTradeMethodsLoading ||
      isReceiptsLoading,
    [
      authenticated,
      isAuthLoading,
      order,
      isOrderLoading,
      isBanksLoading,
      isCurrenciesLoading,
      isMetaLoading,
      isTradeMethodsLoading,
      isReceiptsLoading,
    ],
  );

  const isTSBP = useMemo(
    () => selectedTradeMethod?.paymentType === PaymentType.TSBP,
    [selectedTradeMethod],
  );

  const isTSBPSingleBankSelection = useMemo(
    () =>
      isTSBP &&
      customerFields.length === 1 &&
      customerFields[0].type === FormFieldType.Bank,
    [customerFields, isTSBP],
  );

  const requisitesBankName = useMemo(
    () =>
      find(banks, ['code', order?.requisites?.bank])?.name ||
      order?.requisites?.bank ||
      '',
    [banks, order?.requisites?.bank],
  );

  const customerBankName = useMemo(
    () =>
      find(banks, ['code', order?.payment.customerBank])?.name ||
      order?.payment.customerBank ||
      '',
    [banks, order?.payment.customerBank],
  );

  const handlePaymentFormValuesChange = useCallback(
    (value: Partial<PaymentFormValues>) => {
      setPaymentFormValues((prev) => ({ ...prev, ...value }));
      if (isTSBPSingleBankSelection) {
        confirmCard(value);
      }
    },
    [confirmCard, isTSBPSingleBankSelection],
  );

  const refetchOrderStatus = useCallback(() => {
    setTimeoutRefetch(true);
  }, []);

  const contextValue = useMemo(
    (): OrderContextProps => ({
      order,
      tradeMethod: selectedTradeMethod,
      isTSBP,
      isTSBPSingleBankSelection,
      requisitesBankName,
      customerBank: order?.payment.customerBank,
      customerBankName,
    }),
    [
      customerBankName,
      isTSBP,
      isTSBPSingleBankSelection,
      order,
      requisitesBankName,
      selectedTradeMethod,
    ],
  );

  const { data: { loader } = {} } = useSciDomain();

  if (isLoading) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-w-screen tw-min-h-screen tw-bg-layout">
        {loader ? (
          <img
            className="tw-w-88 tw-animate-pulse"
            src={loader}
            alt="logo-loader"
          />
        ) : (
          <span className="tw-text-secondary tw-text-center">
            {tCommon('components.payment_loading.loading')}
          </span>
        )}
      </div>
    );
  }

  if (!order || authenticationFailed) {
    return <NotFoundPage />;
  }

  return (
    <OrderContext.Provider value={contextValue}>
      <Layout
        order={order}
        banks={banks}
        status={status}
        cardConfirmed={isCustomerDataConfirmed}
      >
        {status === OrderStatus.New && (
          <PaymentRules
            tradeMethods={tradeMethods}
            handleSelect={selectPaymentType}
          />
        )}
        {status === OrderStatus.Requisites && (
          <PaymentLoading status={OrderStatus.Requisites} />
        )}
        {status === OrderStatus.CustomerConfirm &&
          selectedTradeMethod &&
          //  TODO should show PaymentCard according to customerConfirmStatusDetails?
          ((customerDataBeforePayment && !isCustomerDataConfirmed) ||
          (!customerDataBeforePayment &&
            order?.statusDetails ===
              CustomerConfirmStatusDetails.CustomerPayed) ? (
            <PaymentCard
              order={order}
              banks={banks}
              tradeMethods={tradeMethods}
              customerFields={customerFields}
              paymentFormValues={paymentFormValues}
              onPaymentFormValuesChange={handlePaymentFormValuesChange}
              refetchOrderStatus={refetchOrderStatus}
              actions={
                isTSBPSingleBankSelection
                  ? [actions.cancel]
                  : [actions.cancel, actions.confirmCard]
              }
            />
          ) : (
            <Fragment>
              <PaymentDetails
                order={order}
                tradeMethod={selectedTradeMethod}
                currencies={currencies}
                banks={banks}
                refetchOrderStatus={refetchOrderStatus}
                actions={[actions.cancel, actions.confirmPayment]}
              />
              {order?.shop?.collectCustomerReceipts && (
                <UploadReceiptDialog
                  open={uploadReceiptModalOpen}
                  order={order}
                  onClose={() => setUploadReceiptModalOpen(false)}
                  onUpload={() => confirmPayment(orderId)}
                  localeOverride={uploadModalLocaleOverride}
                />
              )}
            </Fragment>
          ))}
        {status === OrderStatus.TraderConfirm && (
          <PaymentLoading status={OrderStatus.TraderConfirm} />
        )}
        {(status === OrderStatus.Completed ||
          status === OrderStatus.Cancelled ||
          status === OrderStatus.Dispute) && (
          <PaymentStatus
            order={order}
            actions={[actions.backToShop]}
            telegramSupportBotUrl={telegramSupportBotUrl}
            receipts={receipts}
            onReceiptsUpload={refetchOrder}
          />
        )}
      </Layout>
    </OrderContext.Provider>
  );
};
