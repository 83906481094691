import axios from 'axios';

import { env } from 'config';
import { FormFieldOverride, Order, OrderReceipt, UpdateOrderDto } from 'types';

const url = `${env.apiUrl}/v1/customer/orders`;

const getOne = async (id: string): Promise<Order> =>
  (await axios.get(`${url}/${id}`)).data;

const update = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateOrderDto;
}): Promise<Order> => (await axios.patch(`${url}/${id}`, data)).data;

const cancel = async (id: string): Promise<Order> =>
  (await axios.post(`${url}/${id}/cancel`)).data;

const startPayment = async (id: string): Promise<Order> =>
  (await axios.post(`${url}/${id}/start-payment`)).data;

const confirmPayment = async (id: string): Promise<Order> =>
  (await axios.post(`${url}/${id}/confirm-payment`)).data;

const getPaymentFields = async (id: string): Promise<FormFieldOverride[]> =>
  (await axios.get(`${url}/${id}/payment-fields`)).data;

const uploadReceipt = async ({
  orderId,
  body,
}: {
  orderId: string;
  body: any;
}) => (await axios.post(`${url}/${orderId}/receipts`, body)).data;

const getOrderReceipts = async (orderId: string): Promise<OrderReceipt[]> =>
  (await axios.get(`${url}/${orderId}/receipts`)).data;

export const ordersApi = {
  getOne,
  update,
  cancel,
  startPayment,
  confirmPayment,
  getPaymentFields,
  uploadReceipt,
  getOrderReceipts,
};
