import React, { useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { sciDomainsApi } from 'api';
import { QueryKey } from 'enums';
import { SciDomain } from 'types';

const SciDomainContext = React.createContext<UseQueryResult<
  SciDomain | undefined
> | null>(null);

export const SciDomainProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryResultSciDomain = useQuery(
    QueryKey.SciDomains,
    () => sciDomainsApi.getOne(),
    { refetchOnWindowFocus: false, refetchInterval: 0, retry: false },
  );

  return (
    <SciDomainContext.Provider value={queryResultSciDomain}>
      {children}
    </SciDomainContext.Provider>
  );
};

export const useSciDomain = () => {
  const context = useContext(SciDomainContext);
  if (context === null) {
    throw new Error('useSciDomain must be used within a SciDomainProvider');
  }

  return context;
};
