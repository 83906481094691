import cx from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useCountdown,
  CountdownCircleTimer,
} from 'react-countdown-circle-timer';

import { MOBILE_HAND_PATH } from 'constants/images.constants';
import { Order } from 'types';

import './CountdownTimer.style.scss';

type Props = {
  order: Order;
  withPlaceholder?: boolean;
  refetchOrderStatus: () => void;
};

const TIMEOUT_LIMIT_SECONDS = 900;
const DEFAULT_COLOR = '#fff';
const TIMER_SIZE = 90;

export const CountdownTimer: React.FC<Props> = ({
  order,
  withPlaceholder = false,
  refetchOrderStatus,
}) => {
  const { statusTimeoutAt } = useMemo(() => order || {}, [order]);
  const timeUntilTimeoutInSeconds = useMemo(
    () => Math.round((new Date(statusTimeoutAt).getTime() - Date.now()) / 1000),
    [statusTimeoutAt],
  );
  const countdownDurationUntilShowInSeconds = useMemo(
    () => Math.max(timeUntilTimeoutInSeconds - TIMEOUT_LIMIT_SECONDS, 0),
    [timeUntilTimeoutInSeconds],
  );
  const countdownDurationAfterShowInSeconds = useMemo(
    () => Math.min(TIMEOUT_LIMIT_SECONDS, timeUntilTimeoutInSeconds),
    [timeUntilTimeoutInSeconds],
  );
  const [shouldRender, setShouldRender] = useState(
    !countdownDurationUntilShowInSeconds,
  );

  useCountdown({
    isPlaying: true,
    duration: countdownDurationUntilShowInSeconds,
    colors: DEFAULT_COLOR,
    onComplete: () => {
      setShouldRender(true);
    },
  });

  const roundTime = useCallback(
    (time: number) => String(time).padStart(2, '0'),
    [],
  );

  const formatTime = useCallback(
    (time: number) =>
      `${roundTime(Math.floor(time / 60))}:${roundTime(time % 60)}`,
    [roundTime],
  );

  return (
    <>
      <div
        className={cx(
          'tw-relative tw-flex-none tw-w-16 tw-h-16 tw-mr-4 tw-rounded-2xl tw-bg-payment-layout-out tw-overflow-hidden md:tw-block md:tw-w-24 md:tw-h-24 md:tw-mr-8',
          {
            '!tw-hidden': shouldRender || !withPlaceholder,
          },
        )}
      >
        <img
          className="tw-absolute tw-right-0 tw-bottom-0 tw-w-20"
          src={MOBILE_HAND_PATH}
          alt="hand-with-mobile"
        />
      </div>
      <div
        className={cx(
          'timer-wrapper tw-block tw-w-16 tw-h-16 tw-mr-4 md:tw-w-24 md:tw-h-24 md:tw-mr-8',
          {
            '!tw-hidden': !shouldRender,
          },
        )}
      >
        <CountdownCircleTimer
          isPlaying={shouldRender || !countdownDurationUntilShowInSeconds}
          initialRemainingTime={countdownDurationAfterShowInSeconds}
          duration={TIMEOUT_LIMIT_SECONDS}
          colors={DEFAULT_COLOR}
          size={TIMER_SIZE}
          rotation="counterclockwise"
          trailColor={DEFAULT_COLOR}
          onComplete={refetchOrderStatus}
        >
          {({ remainingTime }) => (
            <div className="tw-text-sm tw-text-countdown md:tw-text-lg">
              {formatTime(remainingTime)}
            </div>
          )}
        </CountdownCircleTimer>
      </div>
    </>
  );
};
