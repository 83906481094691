import { useSciDomain } from 'components/SciDomainContext';

export const Footer = () => {
  const { data: { copyright } = {}, isLoading, isError } = useSciDomain();

  if (isLoading && !isError) {
    return null;
  }

  if (!copyright) {
    return null;
  }

  return (
    <div className="tw-mt-auto tw-text-secondary tw-text-center tw-m-3">
      {copyright}
    </div>
  );
};
