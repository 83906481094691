import {
  CHECK_ICON_PATH,
  CROSS_ICON_PATH,
  IMAGES_PATH,
} from 'constants/images.constants';
import { PaymentStatusView } from 'enums';

export const ICON_CONFIG: Record<
  PaymentStatusView,
  { iconPath: string; iconBg: string }
> = {
  [PaymentStatusView.Success]: {
    iconPath: CHECK_ICON_PATH,
    iconBg: 'tw-bg-success',
  },
  [PaymentStatusView.Cancel]: {
    iconPath: CROSS_ICON_PATH,
    iconBg: 'tw-bg-error',
  },
  [PaymentStatusView.Dispute]: {
    iconPath: CROSS_ICON_PATH,
    iconBg: 'tw-bg-error',
  },
  [PaymentStatusView.Processing]: {
    iconPath: `${IMAGES_PATH}exclamation-cloud-white.svg`,
    iconBg: 'tw-bg-info',
  },
};
