import { isEmpty } from 'lodash';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, UploadReceiptDialog } from 'components';
import {
  IMAGE_ICON_PATH,
  TELEGRAM_ICON_PATH,
} from 'constants/images.constants';
import { EventName, EventOptionClickAction } from 'enums';
import { TranslationNamespace } from 'i18n';
import { Order, OrderReceipt } from 'types';
import { gaUtils } from 'utils';

type Props = {
  order: Order;
  receipts?: OrderReceipt[];
  telegramSupportBotUrl?: string;
  onReceiptsUpload: () => void;
};

export const PaymentReceiptsDispute: React.FC<Props> = ({
  order,
  receipts,
  telegramSupportBotUrl,
  onReceiptsUpload,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.payment_receipts_dispute',
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const handleTelegramOrderHelp = useCallback(() => {
    gaUtils.trackEvent(EventName.Click, {
      action: EventOptionClickAction.TelegramHelp,
    });
    window.open(`${telegramSupportBotUrl}?start=order_${order.id}`, '_blank');
  }, [order.id, telegramSupportBotUrl]);

  const handleTelegramUploadReceipt = useCallback(() => {
    gaUtils.trackEvent(EventName.Click, {
      action: EventOptionClickAction.TelegramUploadReceipt,
    });
    window.open(`${telegramSupportBotUrl}?start=receipt`, '_blank');
  }, [telegramSupportBotUrl]);

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      {isEmpty(receipts) ? (
        <Fragment>
          <div className="tw-text-primary tw-text-md tw-font-bold tw-text-center md:tw-text-lg">
            {t('no_receipts.title')}
          </div>

          <div className="tw-text-primary tw-text-md tw-text-center tw-mb-6 md:tw-mb-8 md:tw-text-lg">
            {t('no_receipts.description')}
          </div>

          <Button
            className="tw-max-w-md tw-max-h-16 tw-mb-4"
            iconPath={IMAGE_ICON_PATH}
            color="primary"
            onClick={() => setUploadModalOpen(true)}
          >
            {t('buttons.upload')}
          </Button>
          <span
            className="tw-text-secondary tw-text-l tw-underline tw-text-center tw-cursor-pointer"
            onClick={handleTelegramUploadReceipt}
          >
            {t('buttons.upload_by_telegram')}
          </span>
        </Fragment>
      ) : (
        <Fragment>
          <div className="tw-text-primary tw-text-md tw-font-bold tw-text-center tw-mb-8 md:tw-text-lg">
            {t('receipts_uploaded.title')}
          </div>

          <div className="tw-text-primary tw-text-md tw-text-center tw-mb-6 md:tw-mb-8 md:tw-text-lg">
            {t('receipts_uploaded.description')}
          </div>

          <Button
            className="tw-max-w-xs tw-max-h-16"
            iconPath={TELEGRAM_ICON_PATH}
            iconClassName="md:tw-w-9"
            color="info"
            onClick={handleTelegramOrderHelp}
          >
            {t('buttons.support')}
          </Button>
        </Fragment>
      )}

      <UploadReceiptDialog
        open={uploadModalOpen}
        order={order}
        onUpload={onReceiptsUpload}
        onClose={() => setUploadModalOpen(false)}
      />
    </div>
  );
};
