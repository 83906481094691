import * as Yup from 'yup';

import { GetFieldValidatorFn } from 'types';

import { getTCommon } from './fields.utils';

export const getBaseFieldValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();

  let schema = Yup.string();
  if (required) {
    schema = schema.required(tCommon('errors.required'));
  }

  return schema;
};
