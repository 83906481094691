import { find } from 'lodash';
import React, { ReactNode, useMemo } from 'react';

import { PaymentSteps, PaymentTitle } from 'components';
import {
  CustomerConfirmStatusDetails,
  CustomerDataCollectionOrder,
  OrderStatus,
  OrderStatusView,
} from 'enums';
import { Bank, Order } from 'types';

type Props = {
  order: Order;
  banks: Array<Bank>;
  status: OrderStatus;
  cardConfirmed: Boolean;
  children: ReactNode;
};

export const Layout: React.FC<Props> = ({
  order,
  banks,
  status,
  cardConfirmed,
  children,
}: Props) => {
  const bank = useMemo(
    () => order?.requisites?.bank,
    [order?.requisites?.bank],
  );
  const shopName = useMemo(() => order?.shop?.name, [order?.shop?.name]);
  const bankName = useMemo(
    () => find(banks, ['code', bank])?.name || '',
    [bank, banks],
  );

  const customerDataBeforePayment = useMemo(
    () =>
      order?.shop.customerDataCollectionOrder ===
      CustomerDataCollectionOrder.BeforePayment,
    [order?.shop.customerDataCollectionOrder],
  );

  const innerStatus = useMemo(() => {
    if (status === OrderStatus.CustomerConfirm) {
      return (customerDataBeforePayment && !cardConfirmed) ||
        (!customerDataBeforePayment &&
          order.statusDetails === CustomerConfirmStatusDetails.CustomerPayed)
        ? OrderStatusView.CustomerConfirmCard
        : OrderStatusView.CustomerConfirmTransfer;
    }

    return status as unknown as OrderStatusView;
  }, [status, customerDataBeforePayment, cardConfirmed, order.statusDetails]);

  const stepIndex = useMemo(
    () => ({
      [OrderStatusView.New]: 1,
      [OrderStatusView.Requisites]: 2,
      [OrderStatusView.TraderConfirm]: 4,
      [OrderStatusView.CustomerConfirm]: 3,
      ...(customerDataBeforePayment
        ? {
            [OrderStatusView.CustomerConfirmCard]: 3,
            [OrderStatusView.CustomerConfirmTransfer]: 4,
          }
        : {
            [OrderStatusView.CustomerConfirmCard]: 4,
            [OrderStatusView.CustomerConfirmTransfer]: 3,
          }),
      [OrderStatusView.Cancelled]: 5,
      [OrderStatusView.Completed]: 5,
      [OrderStatusView.Dispute]: 5,
      [OrderStatusView.Error]: 5,
    }),
    [customerDataBeforePayment],
  );

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-sm tw-mx-auto md:tw-max-w-7xl">
      <div className="tw-w-full tw-mb-12 md:tw-mb-12 md:tw-w-auto md:tw-max-w-full">
        <PaymentSteps stepsCount={5} activeStep={stepIndex[innerStatus]} />
      </div>
      <div className="tw-w-full tw-mb-12 md:tw-mb-24">
        <PaymentTitle
          stepName={innerStatus}
          storeName={shopName}
          bankName={bankName}
          paymentType={order.payment.type}
        />
      </div>
      {children}
    </div>
  );
};
