import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Bank } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  isTSBP?: boolean;
  customerBank?: string;
  customerBankName?: string;
  requisitesCountry?: string;
  requisitesBank?: string;
};

const DetailsRow = ({ index, text }: { index: string; text: string }) => (
  <li className="tw-flex tw-items-center tw-mb-2 tw-text-primary last-of-type:tw-mb-0">
    <div className="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-basis-9 tw-h-9 tw-mr-4 tw-font-bold tw-text-layout-accent tw-rounded-full tw-bg-layout-accent-alt">
      {index}
    </div>
    {text}
  </li>
);

export const PaymentDetailsRules: React.FC<Props> = ({
  isTSBP,
  customerBank,
  customerBankName,
  requisitesCountry,
  requisitesBank,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.payment_details',
  });

  const isNamba = useMemo(
    () => requisitesBank === Bank.Namba,
    [requisitesBank],
  );

  const renderTSBPSberbankRules = useCallback(
    () => (
      <ul>
        <DetailsRow
          index="01"
          text={t('rules_tsbp_sberbank.rule_one', {
            bank: customerBankName,
          })}
        />
        <DetailsRow
          index="02"
          text={t('rules_tsbp_sberbank.rule_two', {
            country: requisitesCountry,
          })}
        />
        <DetailsRow index="03" text={t('rules_tsbp_sberbank.rule_three')} />
        <DetailsRow index="04" text={t('rules_tsbp.rule_last')} />
        <li className="tw-text-primary tw-font-bold tw-mt-4">
          {t('rules_tsbp.other_banks')}
        </li>
      </ul>
    ),
    [customerBankName, requisitesCountry, t],
  );

  const renderTSBPTinkoffRules = useCallback(
    () => (
      <ul>
        <DetailsRow
          index="01"
          text={t('rules_tsbp_tinkoff.rule_one', {
            bank: customerBankName,
          })}
        />
        <DetailsRow
          index="02"
          text={t('rules_tsbp_tinkoff.rule_two', {
            country: requisitesCountry,
          })}
        />
        <DetailsRow index="03" text={t('rules_tsbp_tinkoff.rule_three')} />
        <DetailsRow index="04" text={t('rules_tsbp.rule_last')} />
        <li className="tw-text-primary tw-font-bold tw-mt-4">
          {t('rules_tsbp.other_banks')}
        </li>
      </ul>
    ),
    [customerBankName, requisitesCountry, t],
  );

  const renderTBankKgsRules = useCallback(
    () => (
      <ul>
        <DetailsRow index="01" text={t('rules_tbank_kgs.rule_one')} />
        <DetailsRow index="02" text={t('rules_tbank_kgs.rule_two')} />
        <DetailsRow index="03" text={t('rules_tbank_kgs.rule_three')} />
        <DetailsRow index="04" text={t('rules_tbank_kgs.rule_four')} />
        <DetailsRow index="05" text={t('rules_tbank_kgs.rule_five')} />
      </ul>
    ),
    [t],
  );

  const renderDefaultRules = useCallback(
    () => (
      <ul>
        <DetailsRow index="01" text={t('rules.rule_one')} />
        <DetailsRow index="02" text={t('rules.rule_two')} />
        <DetailsRow index="03" text={t('rules.rule_three')} />
      </ul>
    ),
    [t],
  );

  const renderRules = useCallback(() => {
    if (isTSBP) {
      if (customerBank === Bank.Sberbank) {
        return renderTSBPSberbankRules();
      }

      if (customerBank === Bank.Tinkoff) {
        return renderTSBPTinkoffRules();
      }

      return null;
    }

    if (isNamba) {
      return renderTBankKgsRules();
    }

    return renderDefaultRules();
  }, [
    isTSBP,
    isNamba,
    customerBank,
    renderTSBPSberbankRules,
    renderTSBPTinkoffRules,
    renderTBankKgsRules,
    renderDefaultRules,
  ]);

  return renderRules();
};
