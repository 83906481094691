import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard } from 'components';
import { ERROR_ICON_PATH } from 'constants/images.constants';
import { TranslationNamespace } from 'i18n';

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common);

  return (
    <InfoCard
      title={t('pages.not_found.title')}
      description={t('pages.not_found.description')}
      icon={{
        src: ERROR_ICON_PATH,
        alt: 'error',
      }}
    />
  );
};
