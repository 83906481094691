import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { LOGIN_URL, authApi } from 'api';
import { authUtils } from 'utils';

axios.interceptors.response.use(
  (config) => config,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig;
    const statusCode = error?.response?.status;
    const url = error?.config?.url;
    if (url && url !== LOGIN_URL) {
      const authData = authUtils.getAuthData();
      if (statusCode === 401 && !(originalRequest as any)._retry && authData) {
        try {
          (originalRequest as any)._retry = true;
          const { accessToken } = await authApi.login(authData);
          authUtils.setAccessToken(accessToken);
          return axios(originalRequest);
        } catch (error) {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use((config) => {
  const accessToken = authUtils.getAccessToken();
  if (accessToken) {
    config.headers.set('Authorization', `Bearer ${accessToken}`);
  }
  return config;
});
