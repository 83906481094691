import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageCode } from '../enums';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const language = useMemo(
    () => i18n.resolvedLanguage as LanguageCode,
    [i18n.resolvedLanguage],
  );
  return {
    language,
  };
};
