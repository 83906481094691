import cx from 'classnames';
import { range } from 'lodash';
import React, { useMemo } from 'react';

type Props = {
  stepsCount: number;
  activeStep: number;
};

export const PaymentSteps: React.FC<Props> = ({
  stepsCount,
  activeStep,
}: Props) => {
  const divisionsNumber = useMemo(() => stepsCount * 2, [stepsCount]);
  const divisionWidth = useMemo(
    () => `${Math.floor(100 / divisionsNumber) + 1}%`,
    [divisionsNumber],
  );
  const lastColorDivision = useMemo(() => 2 * activeStep - 1, [activeStep]);

  return (
    <div className="tw-relative tw-max-w-full tw-w-full tw-mx-auto">
      <div className="tw-absolute tw-w-full tw-bottom-3">
        {/* Uncolored progress line */}
        <div className="tw-absolute tw-w-full tw-flex">
          {range(divisionsNumber).map((index) => (
            <div
              key={index}
              className={cx('tw-h-0.5 tw-bg-steps', {
                '!tw-bg-inherit': index === 0 || index === divisionsNumber - 1,
              })}
              style={{ width: divisionWidth }}
            />
          ))}
        </div>
        {/* Colored progress line */}
        <div className="tw-absolute tw-w-full tw-flex">
          {range(divisionsNumber).map((index) => (
            <div
              key={index}
              className={cx('tw-h-0.5 tw-bg-component-accent', {
                'tw-bg-inherit':
                  index === 0 ||
                  index > lastColorDivision ||
                  index === divisionsNumber - 1,
              })}
              style={{ width: divisionWidth }}
            />
          ))}
        </div>
      </div>
      <div className="tw-flex tw-justify-around tw-w-full">
        {range(stepsCount).map((stepKey) => {
          const currentStep = stepKey + 1;
          const isActive = currentStep === activeStep;
          const isFinished = currentStep < activeStep;

          return (
            <div className="tw-flex tw-flex-col tw-items-center" key={stepKey}>
              <div
                className={cx(
                  'tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-1 tw-mb-2 md:tw-px-10 md:tw-py-3 md:tw-mx-3 md:tw-mb-6 tw-rounded-lg tw-text-lg tw-font-bold md:tw-rounded-2xl md:tw-text-2xl',
                  {
                    'tw-bg-component-accent tw-text-component': isActive,
                    'tw-bg-component tw-text-component-accent': !isActive,
                  },
                )}
              >
                {`0${currentStep}`}
              </div>
              <div
                className={cx(
                  'tw-relative tw-w-6 tw-h-6 tw-bg-layout tw-rounded-full tw-border-2',
                  {
                    'tw-border-component-accent': isFinished || isActive,
                    'tw-border-steps': !isFinished && !isActive,
                  },
                )}
              >
                {(isFinished || isActive) && (
                  <div
                    className={`tw-absolute tw-top-2/4 tw-left-2/4 tw-w-2.5 tw-h-2.5 tw-bg-component-accent tw-rounded-full -tw-translate-x-2/4 -tw-translate-y-2/4`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
