export enum QueryKey {
  Login = 'login',
  Order = 'order',
  OrderPaymentFields = 'order_payment_fields',
  OrderReceipts = 'order_receipts',
  Requisites = 'requisites',
  TraderConfirm = 'trader-confirm',
  CallbackUrlStatus = 'callback-url-status',
  Banks = 'banks',
  Currencies = 'currencies',
  PaymentTypes = 'payment-types',
  TradeMethods = 'trade-methods',
  SelectedTradeMethodsFallback = 'selected-trade-methods-fallback',
  Meta = 'meta',
  SciDomains = 'sci-domains',
}
