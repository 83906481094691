import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/customer/trade-methods/orders`;

const getAll = async (orderId: string) =>
  (await axios.get(`${url}/${orderId}`)).data;

const getSelectedTradeMethod = async (orderId: string) =>
  (await axios.get(`${url}/${orderId}/selected`)).data;

export const tradeMethodsApi = {
  getAll,
  getSelectedTradeMethod,
};
