import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/banks`;

const getAll = async () => (await axios.get(url)).data;

export const banksApi = {
  getAll,
};
