import { LanguageCode } from 'enums';

export const LANGUAGES = [
  { code: LanguageCode.English, name: 'English' },
  { code: LanguageCode.Russian, name: 'Русский' },
  { code: LanguageCode.Armenian, name: 'Հայոց լեզու' },
  { code: LanguageCode.Azerbaijani, name: 'Azərbaycan' },
  { code: LanguageCode.Spanish, name: 'Español' },
  { code: LanguageCode.Hindi, name: 'हिन्दी' },
  { code: LanguageCode.Kyrgyz, name: 'Кыргызча' },
  { code: LanguageCode.Kazakh, name: 'Қазақша' },
  { code: LanguageCode.Tajik, name: 'Тоҷикӣ' },
  { code: LanguageCode.Turkish, name: 'Türkçe' },
  { code: LanguageCode.Ukrainian, name: 'Українська' },
  { code: LanguageCode.Uzbek, name: 'Oʻzbekcha' },
];

export const LANGUAGE_FLAGS: Record<string, string> = {
  en: 'us',
  hi: 'in',
  idn: 'id',
};
