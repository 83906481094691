import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/v1/currencies/fiat`;

const getFiat = async () => (await axios.get(url)).data;

export const currencyApi = {
  getFiat,
};
