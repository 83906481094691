import { filter, find, flatten, isArray, map, transform } from 'lodash';
import React, { useMemo } from 'react';

import { FormFieldType } from 'enums';
import { FormField, PaymentFormValues } from 'types';

import { fieldsList, multiFieldsList } from './common';

const COMBINED_TYPES: FormFieldType[][] = [
  [FormFieldType.FirstCardDigits, FormFieldType.LastCardDigits],
];

type Props = {
  fields?: FormField[];
  paymentFormValues?: PaymentFormValues;
  onPaymentFormValuesChange: (data: PaymentFormValues) => void;
};

export const FormFields: React.FC<Props> = ({
  fields = [],
  paymentFormValues = {},
  onPaymentFormValuesChange,
}) => {
  const groupedFields = useMemo(
    () =>
      transform(
        fields,
        (acc, field) => {
          const existing: FormFieldType[] = map(flatten(acc), 'type');
          if (existing.includes(field.type)) {
            return;
          }
          const fieldCombinedTypes = find(COMBINED_TYPES, (types) =>
            types.includes(field.type),
          );
          if (!fieldCombinedTypes) {
            return acc.push(field);
          }
          const otherToCombine = filter(
            fields,
            (f) => fieldCombinedTypes.includes(f.type) && f.type !== field.type,
          );
          acc.push([field, ...otherToCombine]);
        },
        [] as (FormField | FormField[])[],
      ),
    [fields],
  );

  const currentFields = useMemo(
    () =>
      groupedFields.map((field, index) => {
        if (isArray(field)) {
          const firstField = field[0];
          const component = multiFieldsList[firstField.type];
          if (!component) {
            return null;
          }
          const { Field } = component;
          return (
            <Field
              key={firstField.name}
              autoFocus={!index}
              fields={map(field, (f) => ({
                ...f,
                value:
                  paymentFormValues[f.name as keyof PaymentFormValues] || '',
              }))}
              onChange={(value, name) =>
                onPaymentFormValuesChange({ [name]: value })
              }
            />
          );
        }

        const { type, name } = field;
        const component = fieldsList[type];
        if (!component) {
          return null;
        }
        const { Field } = component;
        const value = paymentFormValues[name as keyof PaymentFormValues] || '';
        return (
          <Field
            key={name}
            autoFocus={!index}
            field={{ ...field, value }}
            onChange={(value) => onPaymentFormValuesChange({ [name]: value })}
          />
        );
      }),
    [groupedFields, onPaymentFormValuesChange, paymentFormValues],
  );

  if (!currentFields.length) {
    return null;
  }

  return <div className="tw-grid tw-gap-y-2">{currentFields}</div>;
};
