import React from 'react';
import SVG from 'react-inlinesvg';

import { IMAGES_PATH } from 'constants/images.constants';
import { PaymentType } from 'enums';

import './CardImage.style.scss';

type Props = {
  bank: string;
  bankName: string;
  paymentType?: string;
  paymentTypeName: string;
  className?: string;
};

const SPECIAL_CARDS: Record<string, string> = {
  [PaymentType.SberPay]: 'sberpay-card.svg',
  [PaymentType.TSBP]: 'tsbp-card.svg',
};

export const CardImage: React.FC<Props> = ({
  bank,
  bankName,
  paymentType = '',
  paymentTypeName,
  ...rest
}) => {
  const card = SPECIAL_CARDS[paymentType] || `${bank || paymentType}-card.svg`;

  const title = `${paymentTypeName} ${bankName}`;

  return (
    <SVG src={`${IMAGES_PATH}cards/${card}`} title={title} {...rest}>
      <SVG src={`${IMAGES_PATH}cards/card.svg`} title={title} {...rest} />
    </SVG>
  );
};
