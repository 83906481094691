import { map, filter } from 'lodash';
import React from 'react';

import { Button } from 'components';

export type PaymentLayoutAction = {
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
};

type Props = {
  title?: string;
  titleClassName?: string;
  actions?: PaymentLayoutAction[];
  children: React.ReactNode | React.ReactNode[];
};

export const PaymentLayout: React.FC<Props> = ({
  title,
  titleClassName,
  actions,
  children,
}) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-relative tw-z-10 tw-w-full tw-max-w-3xl tw-mx-auto tw-pb-8 tw-rounded-2xl tw-bg-payment-layout md:tw-pb-0 md:tw-bg-transparent">
    <div className="tw-absolute tw-top-0 -tw-z-10 tw-w-full tw-max-w-3xl tw-h-full tw-rounded-2xl tw-bg-payment-layout-out md:tw-top-14" />
    <div
      className={`${
        titleClassName ? `${titleClassName} ` : ''
      } tw-flex tw-justify-center tw-items-center tw-z-10 tw-w-full tw-max-w-2xl tw-p-3 tw-pb-6 tw-rounded-2xl tw-bg-payment-layout md:tw-p-10 md:tw-pb-10`}
    >
      {title && <div className="tw-text-2xl tw-font-bold">{title}</div>}
      {children}
    </div>
    <div className="tw-flex tw-flex-col-reverse tw-w-full tw-max-w-2xl tw-px-3 tw-pt-5 md:tw-flex-row md:tw-px-0 md:tw-pt-0">
      {map(
        filter(actions, ({ hidden }) => !hidden),
        ({ label, ...rest }) => (
          <Button
            className="tw-mt-3 last-of-type:tw-mt-0 md:tw-flex-1 md:tw-mr-3 md:last-of-type:tw-mt-3 md:last-of-type:tw-mr-0"
            key={label}
            {...rest}
          >
            {label}
          </Button>
        ),
      )}
    </div>
  </div>
);
