import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CardImage, LogoImage } from 'components';
import { TranslationNamespace } from 'i18n';

import './PaymentOption.style.scss';

type Props = {
  onSelect: () => void;
  bank: string;
  bankName: string;
  paymentType: string;
  paymentTypeName: string;
};

export const PaymentOption: React.FC<Props> = ({
  bank,
  bankName,
  paymentType,
  paymentTypeName,
  onSelect,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.bank_select',
  });

  return (
    <div className="tw-relative tw-h-52 md:tw-h-64">
      <div className="tw-absolute tw-w-full tw-h-40 tw-bg-component tw-rounded-2xl md:tw-h-56" />
      <div className="tw-absolute tw-w-1/2 tw-h-full tw-bg-component tw-rounded-2xl" />
      <div className="tw-absolute tw-left-1/2 tw-bottom-0 tw-w-1/2 tw-h-16 tw-pl-2 tw-pt-2 tw-bg-layout md:tw-h-16 before:tw-block before:tw-absolute before:tw-top-0 before:tw-left-0 before:tw-w-8 before:tw-h-8 before:tw-rounded-tl-2xl before:tw-bg-layout before:tw-shadow-component">
        <Button color="secondary" onClick={onSelect} className="!tw-h-full">
          {t('buttons.select')}
        </Button>
      </div>
      <CardImage
        bank={bank}
        bankName={bankName}
        paymentType={paymentType}
        paymentTypeName={paymentTypeName}
        className="tw-absolute -tw-top-4 tw-left-1/2 tw-w-auto tw-h-36 tw-rounded-lg -tw-translate-x-1/2 md:-tw-top-10 md:tw-h-48"
      />
      <LogoImage
        bank={bank}
        bankName={bankName}
        paymentType={paymentType}
        paymentTypeName={paymentTypeName}
        className="tw-absolute tw-left-1/4 tw-bottom-6 tw-max-w-card-logo tw-max-h-10 tw-text-logo-font tw-leading-5 tw-block tw-overflow-hidden	tw-text-ellipsis -tw-translate-x-1/2 tw-translate-y-1/2 md:tw-bottom-8"
      />
    </div>
  );
};
