export enum EventName {
  Click = 'click',
  Exception = 'exception',
  PaymentStepStart = 'payment_step_start',
  PaymentStepEnd = 'payment_step_end',
  PaymentFlowComplete = 'payment_flow_complete',
}

export enum EventOptionPaymentStepName {
  New = 'select_payment_type',
  Requisites = 'requisites',
  CustomerConfirmDetails = 'customer_confirm_details',
  CustomerConfirmTransfer = 'customer_confirm_transfer',
  TraderConfirm = 'trader_confirm',
}

export enum EventOptionClickAction {
  TelegramHelp = 'telegram_help',
  TelegramUploadReceipt = 'telegram_upload_receipt',
  OrderCancel = 'order_cancel',
  ConfirmCustomerDetails = 'confirm_customer_details',
  ConfirmTransfer = 'confirm_transfer',
  BackToShop = 'back_to_shop',
}
