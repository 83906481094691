import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import AuthCode from 'react-auth-code-input';
import * as AuthCodeDeps from 'react-auth-code-input';

import { FormFieldType } from 'enums';
import { GetFieldValidatorFn, MultiFormFieldProps } from 'types';

import { getTCommon } from '../fields.utils';
import { getBaseFieldValidator } from '../form.validators';

const FIRST_DIGITS_LENGTH = 6;
const LAST_DIGITS_LENGTH = 4;

export const Field: React.FC<MultiFormFieldProps> = ({
  fields,
  autoFocus,
  onChange,
}) => {
  const firstDigitsInputRef = useRef<AuthCodeDeps.AuthCodeRef>(null);
  const lastDigitsInputRef = useRef<AuthCodeDeps.AuthCodeRef>(null);
  const firstDigitsField = useMemo(
    () => find(fields, { type: FormFieldType.FirstCardDigits }),
    [fields],
  );
  const lastDigitsField = useMemo(
    () => find(fields, { type: FormFieldType.LastCardDigits }),
    [fields],
  );
  const name = 'test';
  const required = true;
  const inputClassName = useMemo(
    () =>
      '[&:nth-child(5)]:tw-ml-2 tw-text-center tw-w-4.5 tw-h-4.5 tw-mr-1 tw-bg-secondary-input tw-rounded tw-text-secondary-input tw-text-xs last-of-type:tw-mr-0 sm:tw-w-5 sm:tw-h-5 md:tw-w-8 md:tw-h-8 md:tw-text-2xl',
    [],
  );

  const handleFirstDigitsChange = useCallback(
    (value: string) => {
      if (!onChange || !firstDigitsField) {
        return;
      }
      if (value.length >= FIRST_DIGITS_LENGTH) {
        if (value.length === FIRST_DIGITS_LENGTH) {
          lastDigitsInputRef?.current?.focus();
        }
        onChange(value.slice(0, FIRST_DIGITS_LENGTH), firstDigitsField.name);
        return;
      }
      onChange('', firstDigitsField.name);
    },
    [firstDigitsField, onChange],
  );

  const handleLastDigitsChange = useCallback(
    (value: string) => {
      if (!onChange || !lastDigitsField) {
        return;
      }
      if (value.length >= LAST_DIGITS_LENGTH) {
        onChange(value.slice(0, LAST_DIGITS_LENGTH), lastDigitsField.name);
        return;
      }
      onChange('', lastDigitsField.name);
    },
    [lastDigitsField, onChange],
  );

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-h-14 tw-px-3 tw-text-2xl tw-text-component-accent tw-rounded-xl tw-bg-card-digits-layout md:tw-h-20 md:tw-px-8 md:tw-rounded-2xl md:tw-text-5xl">
      {firstDigitsField ? (
        <div className="tw-flex tw-items-center">
          <AuthCode
            ref={firstDigitsInputRef}
            autoFocus={autoFocus}
            length={FIRST_DIGITS_LENGTH}
            containerClassName="tw-flex tw-mr-1"
            inputClassName={inputClassName}
            allowedCharacters="numeric"
            onChange={handleFirstDigitsChange}
          />
          <span className="tw-relative tw-top-1">{`**`}</span>
        </div>
      ) : (
        <Fragment>
          <span className="tw-relative tw-top-1">{`****`}</span>
          <span className="tw-relative tw-top-1">{`****`}</span>
        </Fragment>
      )}

      <span className="tw-relative tw-top-1">{`****`}</span>
      {lastDigitsField ? (
        <AuthCode
          ref={lastDigitsInputRef}
          autoFocus={autoFocus && !firstDigitsField}
          length={LAST_DIGITS_LENGTH}
          containerClassName="tw-flex"
          inputClassName={inputClassName}
          allowedCharacters="numeric"
          onChange={handleLastDigitsChange}
        />
      ) : (
        <span className="tw-relative tw-top-1">{`****`}</span>
      )}
      <input className="tw-hidden" name={name} required={required} />
    </div>
  );
};

export const getValidator: GetFieldValidatorFn = ({ required }) => {
  const tCommon = getTCommon();
  return getBaseFieldValidator({ required }).matches(
    /^[0-9]{4}$/,
    tCommon('errors.invalid'),
  );
};
