let accessToken: string;

type AuthData = {
  orderId: string;
  token: string;
};

let authData: AuthData;

const getAccessToken = (): string => accessToken;

const setAccessToken = (token: string) => {
  accessToken = token;
};

const setAuthData = (data: AuthData) => {
  authData = data;
};

const getAuthData = (): AuthData => authData;

export const authUtils = {
  setAccessToken,
  getAccessToken,
  getAuthData,
  setAuthData,
};
