import { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import {
  ErrorFallback,
  Footer,
  LanguageSelector,
  useSciDomain,
} from 'components';
import { gaUtils } from 'utils';

export const PageLayout = () => {
  const handleError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      gaUtils.trackException({
        fatal: true,
        description: error.message + info.componentStack,
      });
    },
    [],
  );

  const {
    data: { theme, logo, title } = {},
    isLoading,
    isError,
  } = useSciDomain();

  useEffect(() => {
    if (theme) {
      document.documentElement.dataset.theme = theme;
    }
  }, [theme]);

  if (isLoading && !isError) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-w-screen tw-min-h-screen tw-p-5 tw-bg-layout md:tw-p-10">
      <title>{title}</title>
      <div className="tw-relative tw-flex tw-flex-col tw-items-center tw-w-full tw-max-w-sm tw-mx-auto md:tw-max-w-7xl">
        <div className="tw-absolute tw-top-3 tw-right-0 tw-h-10 md:tw-top-0 md:tw-h-15">
          <LanguageSelector />
        </div>
        {logo && (
          <img
            src={logo}
            alt={title || ''}
            className="tw-h-10 tw-mt-3 tw-mb-12 tw-mx-auto md:tw-mt-0 md:tw-h-15 md:tw-mb-12"
          />
        )}
      </div>

      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Outlet />
      </ErrorBoundary>

      <div className="tw-mt-10 md:tw-mt-20" />
      <Footer />
    </div>
  );
};
