import commonAm from './locales/am/common.json';
import commonAz from './locales/az/common.json';
import commonEn from './locales/en/common.json';
import commonEs from './locales/es/common.json';
import commonHi from './locales/hi/common.json';
import commonKg from './locales/kg/common.json';
import commonKz from './locales/kz/common.json';
import commonRu from './locales/ru/common.json';
import commonTj from './locales/tj/common.json';
import commonTr from './locales/tr/common.json';
import commonUa from './locales/ua/common.json';
import commonUz from './locales/uz/common.json';

export type DefaultResourse = 'ru';
export const defaultResourse: DefaultResourse = 'ru';

export enum TranslationNamespace {
  Common = 'common',
}

export const defaultNS = 'common';

export const resources = {
  am: {
    [defaultNS]: commonAm,
  },
  az: {
    [defaultNS]: commonAz,
  },
  en: {
    [defaultNS]: commonEn,
  },
  es: {
    [defaultNS]: commonEs,
  },
  hi: {
    [defaultNS]: commonHi,
  },
  kg: {
    [defaultNS]: commonKg,
  },
  kz: {
    [defaultNS]: commonKz,
  },
  ru: {
    [defaultNS]: commonRu,
  },
  tj: {
    [defaultNS]: commonTj,
  },
  tr: {
    [defaultNS]: commonTr,
  },
  ua: {
    [defaultNS]: commonUa,
  },
  uz: {
    [defaultNS]: commonUz,
  },
};
